var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column column--width-auto" },
    [
      _c("date-picker", {
        class: {
          "BaseDatePicker__input--error": !_vm.valid,
          "BaseDatePicker--disabled": _vm.disabled,
          clickable: !_vm.disabled
        },
        attrs: {
          "calendar-button": true,
          "calendar-button-icon": "fa fa-calendar-o fa-3 fa-15",
          "calendar-class": "BaseDatePicker__calendar-wrapper",
          disabled: _vm.disabled,
          format: "M-d-yyyy",
          "input-class": "BaseDatePicker__input",
          placeholder: _vm.placeholder,
          value: _vm.value,
          "wrapper-class": "BaseDatePicker"
        },
        on: { input: _vm.emitDate }
      }),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        !_vm.valid
          ? _c("label", { staticClass: "fc-red fs-12" }, [
              _vm._v("\n      " + _vm._s(_vm.instruction) + "\n    ")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
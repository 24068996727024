<template>
  <div class="column column--width-auto">
    <date-picker
      @input="emitDate"
      :calendar-button="true"
      :calendar-button-icon="'fa fa-calendar-o fa-3 fa-15'"
      :calendar-class="'BaseDatePicker__calendar-wrapper'"
      :class="{
        'BaseDatePicker__input--error': !valid,
        'BaseDatePicker--disabled': disabled,
        'clickable': !disabled
      }"
      :disabled="disabled"
      :format="'M-d-yyyy'"
      :input-class="'BaseDatePicker__input'"
      :placeholder="placeholder"
      :value="value"
      :wrapper-class="'BaseDatePicker'"
    />
    <transition name="Transition__fade">
      <label
        v-if="!valid"
        class="fc-red fs-12"
      >
        {{ instruction }}
      </label>
    </transition>
  </div>
</template>

<script>
// Packages
import DatePicker from 'vuejs-datepicker'

export default {
  name: 'BaseDatePicker',

  components: {
    DatePicker,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    instruction: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select a Date'
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: [Date, String],
      required: false,
      default: null,
    },
  },

  created () {
    if (this.value) this.date = this.value
  },

  data () {
    return {
      date: null,
    }
  },

  methods: {
    emitDate (e) {
      this.date = e
      this.$emit('input', this.date)
    },
  },
}
</script>

<style lang="sass">
.BaseDatePicker
  $block: &
  +flex($align: center)
  background-color: $white
  border: none
  border-radius: rem(4px)
  box-shadow: $shadow-a, $shadow-b
  max-height: $input-height
  max-width: rem(200px)
  padding: rem(11px) rem(16px)
  width: inherit

  div:first-child
    +flex($align: center)

  .fa
    margin-right: rem(10px)

  &__calendar-wrapper
    border: rem(1px) solid #D8DCE6
    border-radius: rem(4px)
    padding: rem(8px)

    span.cell.day:hover
      border-color: $branding !important

    header > span
      // Makes the button 38px tall, which is like all other actionable controls
      padding: rem(12px) 0

    span.prev::after
      border-right-color: #9EA0A5

    span.next::after
      border-left-color: #9EA0A5

  &__input,
  &__input::placeholder
    border: none
    color: $text-light
    font-size: rem(14px)
    line-height: rem(22px)
    width: calc(100% - 24px)

  &__input--error
    border: $border
    border-color: $danger

  &--disabled
    background-color: $icon-light
    #{$block}__input
      background-color: $icon-light
      color: $text-middle
</style>